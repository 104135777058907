.container-form.form-validate-whatsapp-main {
  width: 30%;
  min-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 2em;
  height: 600px;
}
.header-validate-whatsapp > img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 1em;
}
.form-validate-whatsapp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-validate {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
