.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 500;
}

/* css modal sesions */
.modal {
  width: 18em;
  padding: 2em 0;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  animation: 0.7s modal ease-in-out;
  position: relative;
}

.container-submitted-modal {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 350px;
  width: 400px;
  border-radius: 1em;
  overflow: hidden;
  gap: 1em;
}

.container-submitted-modal img {
  width: 100%;
}

.container-submitted-modal button {
  margin-bottom: 1em;
}

/*  */

.container-comment-place-modal {
  padding: 1.5em;
}

.container-comment-place-modal .rating {
  width: 80%;
  margin: 0;
}

.container-comment-place-modal .rating .start-container {
  gap: 0.4em;
}

.container-comment-place-modal .start-container p {
  margin: 0;
}

.container-comment-place-modal form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
}

.container-comment-place-modal .btn-photo,
.container-comment-place-modal .btn-cancel,
.container-comment-place-modal .btn-submit {
  margin: 1em 0;
}

/*  */

/* animation modal */
@keyframes modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header-modal {
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
}

.button-modal {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  line-height: 1.5;
  color: rgba(255, 0, 0, 0.6);
}

.button-modal:hover {
  color: rgba(255, 0, 0, 1);
}

.modal-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  width: 12em;
  margin: auto;
}

.modal-image > img {
  object-fit: cover;
  width: 100%;
}

.logo-button {
  width: 1.2em;
  margin-right: 0.5em;
}

/* css modal terminos y condiciones */

.button-open-terms {
  color: blue;
  border: none;
  background-color: transparent;
  font-size: 1em;
  cursor: pointer;
}
.container_modal_condition {
  background-color: white;
  border-radius: 0.5em;
  width: 65%;
  height: 95%;
  padding: 4em 3em;
  animation: modal 0.7s ease;
  box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.5) inset;
}
.container_modal_condition::-webkit-scrollbar {
  width: 0.7em;
  height: 0;
}
.container_modal_condition::-webkit-scrollbar-thumb {
  background-color: rgba(189, 184, 184, 0.8);
  border-radius: 0.4em;
}

.title_modal {
  text-align: center;
  margin-bottom: 0.5em;
}

.parraf_modal {
  text-align: justify;
  font-size: 0.8em;
  margin: 1em 0;
}

.button-close-terms {
  margin: auto;
  background-color: rgba(255 0 0 / 0.8);
  cursor: pointer;
}

/* css modal decision de agregar mascota o no */
.container-modal-new-pet {
  position: absolute;
  top: 1em;
  background-color: red;
  width: 30%;
  padding: 1em 2em;
  right: -56em;
  border-radius: 1em;
}

@keyframes modal-message {
  0% {
    right: -56em;
  }
  50% {
    right: 1em;
  }

  100% {
    right: -56em;
  }
}
.container-modal-new-pet > p,
.container-modal-new-pet > h2 {
  color: white;
}
.container-modal-new-pet > h2 {
  font-weight: bold;
}

/* estilos modal de detalles de mascotas */
.container-details-modal {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  align-items: center;
  max-width: 300px;
  height: 270px;
  width: 400px;
  border-radius: 1em;
  padding: 2em;
}
.details-info > h2 {
  margin-bottom: 0.5em;
}
.details-img {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  border: 2px solid rgba(196, 190, 190, 1);
  margin-bottom: 0.4em;
}
span.close {
  position: absolute;
  top: 1em;
  right: 1em;
  font-weight: bold;
  color: red;
  cursor: pointer;
}
.btn-edit {
  background-color: #1e1547;
}
.details-pets {
  text-align: center;
}
.details-pets > .main-slides-container > .slides-container > .slide > .container-slide-img > img {
  width: 100%;
  height: 100%;
  border-radius: 2em;
  border: 2px solid rgba(196, 190, 190, 1);
  margin-bottom: 0.4em;
}

/* estilos del modal de direcciones */
.container-modal-address {
  position: relative;
  background-color: #fff;
  padding: 2em 1em;
  border-radius: 1em;
  width: 53%;
  height: 90%;
  overflow: hidden;
}
.list-address {
  width: 100%;
  padding: 0.7em;
  height: 92%;
  overflow: auto;
}
.list-address::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 0.3em;
}
.list-address::-webkit-scrollbar-thumb {
  background-color: #1e1547;
  border-radius: 0.3em;
}
.input-address {
  border: none;
  width: 100%;
  padding: 1em;
  outline: 0.2em solid rgba(196, 190, 190, 1);
  border-radius: 3em;
  margin-top: 1em;
}
.address-info {
  padding: 0.4em;
}
.address-info:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}
.close-modal-address {
  position: absolute;
  top: 1em;
  right: 1em;
  color: red;
  font-weight: 900;
  cursor: pointer;
}

/* estilos modal listado de imagenes cargadas */

.list-images {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 800px;
  width: 80%;
  height: 60%;
  border-radius: 1em;
  padding: 1em;
  overflow: auto;
}
.container-image-main {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  margin-bottom: 1.3em;
}

.list-images > button {
  display: inline-block;
  width: fit-content;
}

.image-iten-container {
  width: 30%;
  height: 50%;
  margin: 0 1em 1em 0;
  border-radius: 2em;
  overflow: hidden;
  position: relative;
}
.image-iten-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-images {
  position: absolute;
  top: 1em;
  right: 1em;
  font-weight: 900;
  border: 0.1em solid red;
  color: red;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
  text-align: center;
  line-height: 1.26;
}

/* Modal para la busqueda de tipos de mascotas */
.modal-type-pets-main {
  width: 600px;
  min-width: 300px;
  height: 600px;
  background-color: #f0eaea;
  border-radius: 5px;
}
.modal-header-pets {
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 20px;
  border-radius: 5px 5px 0 0;
}

.modal-header-pets > input {
  width: 100%;
  padding: 1rem;
  margin-left: 0.8rem;
  border: 0.2px solid #b3b0b0;
  border-radius: 25px;
}
.modal-header-pets > input:focus-visible {
  border: 0.2px solid #b3b0b0;
  outline: none;
}
.modal-header-pets > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.modal-result-pets {
  width: 100%;
}

.modal-result-pets > div {
  margin: 0;
  padding: 1em;
  border-bottom: 0.04em solid #b3b0b0;
}

.modal-result-pets > div:hover {
  cursor: pointer;
  background-color: rgba(208, 205, 231, 0.5);
}

.modal-result-pets > .type-no-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  color: #1e1547;
  border-bottom: none;
}

.type-no-found > h3 {
  margin-bottom: 1.2em;
}

/* modal para permitir ubicacion */
.modal-location {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}
.main-get-coord {
  width: 500px;
  height: 200px;
  padding: 1em;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 2em;
}
.main-get-coord > p {
  font-size: 1.3em;
  font-weight: 700;
}
@media screen and (max-height: 450px) {
  .container-modal-new-pet {
    display: none;
  }
}
@media screen and (max-width: 860px) {
  .container-modal-new-pet {
    display: none;
  }
}
@media screen and (max-width: 814px) {
  .main-get-coord {
    width: 300px;
    height: 180px;
    font-size: 0.7em;
  }
}

@media screen and (max-width: 420px) {
  .container-modal-new-pet {
    display: none;
  }
}
