.pet-list-container {
  display: flex;
  margin-bottom: 1em;
  padding: 0 1em;
}

.pet-type {
  display: flex;
  text-align: center;
  margin-right: 6%;
  flex-direction: column;
  align-items: center;
}
.img-container {
  display: block;
  width: 150%;
  height: 66%;
  outline: 1px solid #000;
  border-radius: 2em;
  padding: 3%;
  margin-bottom: 0.6em;
  cursor: pointer;
}
.img-container::before {
  display: none;
}
.img-container > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.pet-type > label {
  position: relative;
  left: 40%;
}

.pet-type > input {
  display: none;
}

.pet-type > label::before {
  content: '';
  position: absolute;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  border: 0.18em solid rgba(196 190 190 / 0.8);
  margin-left: -1.7em;
  margin-top: -0.2em;
  box-shadow: inset 0 0 0 0.17em #fff;
}

.pet-type > input:checked + label::before {
  background-color: red;
}

@media screen and (min-width: 300px) and (max-width: 670px) {
  .pet-list-container {
    width: 400px;
  }
  .pet-type {
    margin-right: 2em;
  }
}

@media screen and (max-width: 800px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .row-6 {
    width: 100%;
  }
}
