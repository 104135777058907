.place-detail-service {
  background-color: white;
  height: 100%;
  left: 0;
  top: 0;
  padding-bottom: 2em;
  width: 100%;
  height: 100vh;
  margin: 0;
  z-index: 700;
  position: relative;
  overflow: auto;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.place-detail-service::-webkit-scrollbar {
  display: none;
}

.place-detail-service .back-button,
.place-detail-service .path-button {
  /* position: relative; */
  position: absolute;
  left: 2em;
  top: 3em;
  width: 3em;
  height: 3em;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: white;
  z-index: 3;
  border-style: none;
  overflow: hidden;
}
.path-button > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-detail-service .path-button {
  left: auto;
  right: 2em;
}

.place-detail-service .back-button i,
.place-detail-service .path-button i {
  font-size: 1.5em;
  text-shadow: none;
}

.place-detail-service .edit-button {
  /* position: relative; */
  position: absolute;
  right: 2em;
  bottom: 3em;
  background-color: #1d1845;
  width: 3em;
  height: 3em;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: white;
  z-index: 3;
  border-style: none;
}

.place-detail-service .edit-button i {
  font-size: 1.5em;
  text-shadow: none;
}

.place-detail-service .details-imgs {
  position: relative;
  width: 420px;
  margin: 0 auto;
}

.place-detail-service .report-img {
  position: relative;
  height: 400px;
  width: 100%;
  border-radius: 0 0 50% 50%;
  object-fit: cover;
  z-index: 2;
}

.place-detail-service .background-img {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.place-detail-service > h2,
.place-detail-service .place-categories {
  color: #1f1646;
  margin: 1em auto;
  text-align: center;
}

.place-detail-service .loading-message {
  margin: 1em;
}

.place-detail-service .comments-container {
  overflow: auto;
}

.place-detail-service .comments-container {
  overflow: auto;
}

.place-detail-service > .rating-stars-container {
  justify-content: center;
}

.container-qualify-place-modal {
  z-index: 20;
}

.place-detail-service > p {
  margin: 1em 0;
  padding: 0.6em;
}

.place-detail-service > .place-distancia {
  text-align: center;
  margin-top: -1.4em;
}

.btn-calificar-container {
  display: flex;
  justify-content: center;
}

.btn-calificar {
  background-color: #1d1845;
  margin: 1em 0;
}

.container-qualify-place-modal {
  padding: 2em;
}

.comment-container {
  display: flex;
  padding: 1em;
}

.comment-container .comment-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-container .comment-left .user-profile-img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  object-fit: cover;
}

.comment-container .comment-right {
  padding: 0.5em;
}

.comment-container .comment-right p {
  word-break: break-word;
}

.comment-right .comment {
  margin: 0.5em;
  width: 100%;
  text-align: left;
}

.place-detail-service .no-rating {
  text-align: center;
}

@media (min-width: 750px) {
  .place-detail-service {
    min-width: 420px;
    max-width: 420px;
  }
}
