@import url('./fonts.css');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'WorkSans-Regular', 'WorkSans-Bold', 'WorkSans-Light', 'WorkSans-Black', 'WorkSans-BlackItalic', 'WorkSans-BoldItalic', 'WorkSans-ExtraBold', 'WorkSans-ExtraBoldItalic',
    'WorkSans-ExtraLight', 'WorkSans-ExtraLightItalic', 'WorkSans-Italic', 'WorkSans-LightItalic', 'WorkSans-Medium', 'WorkSans-MediumItalic', 'WorkSans-SemiBold', 'WorkSans-SemiBoldItalic',
    'WorkSans-Thin', 'WorkSans-ThinItalic', sans-serif;
}

.button {
  border: none;
  border-radius: 10em;
  padding: 10px 25px;
  text-align: center;
  font-size: 16px;
}
