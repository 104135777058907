/* estilos lista de mascotas */
.container-main {
  width: 40%;
  max-width: 400px;
}
.list-pets {
  outline: 0.1em solid rgba(196 190 190 / 0.8);
  border-radius: 0.4em;
  height: 600px;
  overflow: auto;
}
.list-pets::-webkit-scrollbar {
  width: 0.3em;
}

.list-pets::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1e1547;
}

.pet-info {
  display: flex;
  align-items: center;
  padding: 0.6em;
}
.pet-info:hover {
  background-color: rgba(196 190 190 / 0.8);
}

.pet-info > img {
  width: 4em;
  height: 4em;
  outline: 0.18em solid rgba(196 190 190 / 0.8);
  border-radius: 50%;
  margin-right: 1.3em;
}
.no-pet {
  display: inline-block;
  padding: 1em;
}
.buttons-pets {
  display: flex;
  justify-content: flex-end;
  gap: 0.4em;
  margin-left: 1em;
}

.button.btn-edit,
.button.btn-del {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
}

.button.btn-edit > img,
.button.btn-del > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* estilos div listado de mascota modo responsive */
.list-pets-responsive {
  border-top: 0.1em solid rgba(196 190 190 / 0.8);
  border-bottom: 0.1em solid rgba(196 190 190 / 0.8);
  display: none;
  padding: 0.3em 1em;
  align-items: center;
  justify-content: space-between;
}
.list-img-pets {
  overflow: hidden;
}
.scroll-images {
  display: flex;
  overflow: auto;
  width: 100%;
}
.scroll-images::-webkit-scrollbar {
  height: 0.4em;
}
.scroll-images::-webkit-scrollbar-thumb {
  background-color: #1e1547;
  border-radius: 2em;
}
.pet-info-responsive {
  display: none;
}
.active-pet-info-responsive {
  display: flex;
}
.pet-info-responsive:hover {
  background-color: #1e1547;
  border-radius: 5em;
  cursor: pointer;
}
.info-pet-responsive {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start !important;
  gap: 0.2em;
  overflow: hidden;
}
.info-pet-responsive > p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pet-info-responsive:hover > .info-pet-responsive > p {
  color: #fff;
}
.container-list-pets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.pets {
  display: flex;
  align-items: center;
}
.pets > p {
  margin-right: 1em;
}
.pet-img-responsive {
  width: 50px;
  height: 50px;
  outline: 1px solid rgba(196 190 190 / 0.8);
  border-radius: 50%;
  margin-right: 0.3em;
}
.info-pet {
  display: flex;
  flex-grow: 1;
  max-width: 200px;
  width: 200px;
  flex-direction: column !important;
  align-items: flex-start !important;
  overflow: hidden;
}
.info-pet > p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pet-info > img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.arrows-responsive {
  display: flex;
  flex-direction: column;
}
.arrows-responsive > .fa-solid {
  transition: all 300ms ease-in-out;
  color: red;
}
.active-arrows {
  transition: all 300ms ease-in-out;
  transform: rotateZ(180deg);
  color: #1e1547 !important;
}
.length-pets {
  margin-left: 0.2em;
}

.hidden-length {
  display: none;
}
.display-list-pets {
  display: block;
  max-height: 0;
  overflow: auto;
  transition: all 1s ease-in-out;
}
.active-list-responsive {
  max-height: 250px;
}
.display-list-pets::-webkit-scrollbar {
  width: 0.5em;
}
.display-list-pets::-webkit-scrollbar-thumb {
  background-color: #1e1547;
  border-radius: 2em;
}

@media screen and (max-width: 1000px) {
  .container-main {
    display: none;
  }
  .list-pets-responsive {
    display: block;
  }
}

@media screen and (max-width: 414px) {
  .list-img-pets {
    width: 210px;
    margin-right: 1em;
  }
}
