.viewer-container {
  position: relative;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 700px;
  height: 95vh;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  z-index: 250;
}
.header-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.btn-close-viewer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: 2em 0 2em 0;
  cursor: pointer;
}

.btn-close-viewer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-slide-images-viewer {
  width: 600px;
  height: 600px;
  overflow: hidden;
  margin-top: -10em;
}
.slide-images-viewer {
  display: flex;
  height: 100%;
}
.container-images-viewer {
  list-style: none;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.container-images-viewer > img {
  width: 600px;
  height: 600px;
  object-fit: contain;
  border-radius: 10%;
}

.arrow-images {
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
}
.arrow-rigth,
.arrow-left {
  cursor: pointer;
  border: none;
}

.fa-chevron-right,
.fa-chevron-left {
  background-color: #000;
  color: #fff;
  font-size: 1.5em;
}

.option-menu {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  left: calc(50% - 25px);
  border-radius: 50%;
  background-color: #fff;
  color: #24334e;
  position: absolute;
  bottom: 4em;
  cursor: pointer;
}
.modal-options {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.container-options {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 150;
}
.close-options {
  border: none;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.close-options > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.options-menu {
  text-align: center;
  width: 100%;
  height: 170px;
  background-color: #fff;
  color: #24334e;
  margin-top: 1.5em;
  border-radius: 2em 2em 0 0;
}
.options-menu > p {
  padding: 1.2em;
}
/* estilos editor de imagenes de documentos */
.container-edit-docs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: 2em 1.3em;
  background-color: #fff;
  z-index: 400;
}
.group-buttons-doc-image {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  height: 60px;
}
.btn-back-doc,
.btn-del-image-doc {
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.btn-back-doc > img,
.btn-del-image-doc > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.container-image-edit {
  border: 0.2px solid #000;
  width: 100%;
  margin: 2em;
  height: 60%;
  overflow: hidden;
}

section.container-image-edit > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.footer-save-doc {
  width: 100%;
  height: 100px;
  display: flex;
}
.form-group-doc {
  width: 94%;
  align-items: center;
}
.form-group-doc > label {
  color: #1f1646;
}
.btn-save-doc {
  background-color: #1f1646;
  margin-top: 1em;
}

.modal-delete {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.details-info {
  color: #1f1646;
}
/* medias querys */
@media screen and (min-width: 300px) {
  .viewer-container {
    width: 490px;
  }
  .container-slide-images-viewer {
    width: 420px;
    height: 350px;
  }
  .container-images-viewer > img {
    width: 420px;
    height: 350px;
  }
}
