.survey-view {
    padding: 10em 6em 6em 5em;
    position: relative;
}

.survey-view .back-button {
    position: absolute;
    left: 6em;
    top: 6em;
    background-color: #eb0045;
    width: 3em;
    height: 3em;
    text-align: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    color: white;
    z-index: 3;
    border-style: none;
  }

.survey-view .corner-decoration {
    position: absolute;
    right: 0;
    top: 0;
}

.survey-view p {
    margin-top: 2em;
}

.form-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 1em;
    position: relative;
}

.survey-view .survey-btn-container {
    display: flex;
    justify-content: right;
}

.survey-view  .survey-btn {
    background-color: #1e1547;
    margin-left: auto;
}