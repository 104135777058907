.button-google {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em 1.5em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.1);
}

.logo-button {
    width: 1.2em;
    margin-right: 0.5em;
}