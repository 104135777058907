.button-filter-pets {
  background-color: #f4f3fa;
  padding: 0.4em 0.6em;
  color: #1e1547;
  border-radius: 25px;
}
.button-filter-pets:visited {
  background-color: #f4f3fa;
}

.button-filter-pets:focus {
  background-color: #d0cde7;
}
