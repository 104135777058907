/* Fuentes a usar en la app */
@font-face {
  font-family: 'WorkSans-Black';
  src: url('../fonts/WorkSans-Black.ttf');
}
@font-face {
  font-family: 'WorkSans-BlackItalic';
  src: url('../fonts/WorkSans-BlackItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-Bold';
  src: url('../fonts/WorkSans-Bold.ttf');
}
@font-face {
  font-family: 'WorkSans-BoldItalic';
  src: url('../fonts/WorkSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: url('../fonts/WorkSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'WorkSans-ExtraBoldItalic';
  src: url('../fonts/WorkSans-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-ExtraLight';
  src: url('../fonts/WorkSans-ExtraLight.ttf');
}
@font-face {
  font-family: 'WorkSans-ExtraLightItalic';
  src: url('../fonts/WorkSans-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-Light';
  src: url('../fonts/WorkSans-Light.ttf');
}
@font-face {
  font-family: 'WorkSans-Italic';
  src: url('../fonts/WorkSans-Italic.ttf');
}
@font-face {
  font-family: 'WorkSans-LightItalic';
  src: url('../fonts/WorkSans-LightItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-Medium';
  src: url('../fonts/WorkSans-Medium.ttf');
}
@font-face {
  font-family: 'WorkSans-MediumItalic';
  src: url('../fonts/WorkSans-MediumItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-Regular';
  src: url('../fonts/WorkSans-Regular.ttf');
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url('../fonts/WorkSans-SemiBold.ttf');
}
@font-face {
  font-family: 'WorkSans-SemiBoldItalic';
  src: url('../fonts/WorkSans-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'WorkSans-Thin';
  src: url('../fonts/WorkSans-Thin.ttf');
}
@font-face {
  font-family: 'WorkSans-ThinItalic';
  src: url('../fonts/WorkSans-ThinItalic.ttf');
}
