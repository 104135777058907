.successful-register-view {
  width: 100%;
  text-align: center;
}

.home-view-right .corner-decoration,
.successful-register-view .corner-decoration {
  position: absolute;
  top: 0;
  right: 0;
  width: 35em;
}

.successful-register-view .message {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 12em auto; */
}

.message h3 {
  margin-top: 1em;
}

.message p {
  margin: 1.5em auto;
}

.message .exit-button {
  background-color: #1e1547;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.message > .logo {
  width: 400px;
  height: 400px;
}
