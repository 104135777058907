.button-wcolor {
  background-color: #1e1547;
  color: #fff;
  font-weight: 600;
  min-width: 200px;
  width: 30%;
  padding: 1em 0;
  margin-left: -0.4em;
  cursor: pointer;
}

input:-webkit-outer-spin-button,
input:-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}
