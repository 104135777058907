.logged-view {
  display: flex;
}

/* .logged-view .logged-view-right {
    margin-left: 190px;
  } */
.logged-view .logged-view-left {
  display: none;
}

.logged-view .logged-view-right {
  flex: 0 1 100%;
  width: 100%;
}

.toggle-collapse {
  display: inline-block;
  width: 45px;
  height: 32px;
  margin: 1em 1em;
  background-color: rgba(197, 187, 244, 0.4);
  color: #1e1547;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 800;
}

.toggle-collapse:hover {
  color: rgb(232, 232, 232);
  background-color: rgba(197, 187, 244, 1);
}

.logged-view-left.collapse {
  width: 100%;
  display: block;
}

.logged-view-right.collapse {
  display: none;
}

@media (min-width: 850px) {
  .logged-view-left.collapse {
    width: 190px;
  }

  .logged-view-right.collapse {
    display: block;
  }

  .logged-view .logged-view-left {
    display: block;
    width: auto;
  }
  .logged-view .logged-view-right {
    margin-left: 190px;
  }

  .toggle-collapse {
    display: none;
  }
}
