.main-slides-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  z-index: 2;
}

.main-slides-container .slider-dots {
  padding: 0.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5em; 
  flex-wrap: wrap;
}

.slides-container {
  width: 100%;
  display: flex;
}

.slide {
  min-width: 100%;
  overflow: hidden;
  transition: 3s ease all;
}

.slides-container,
.main-slides-container {
  height: auto;
}

.controllers {
  width: 100%;
  position: absolute;
  display: flex;
  margin: 0;
  top: 200px;
  color: white;
  font-weight: 700;
  font-size: 1.5em;
  z-index: 3;
}

.arrow-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3em;
  height: 3em;
  text-align: center;
  line-height: 3.4em;
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.arrow-left {
  left: 0em;
}

.arrow-right {
  right: 0em;
}

.arrow-left:hover,
.arrow-right:hover {
  color: #1e1547;
}

.slide-text {
  color: #fff;
  width: 100%;
  padding: 1em;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.container-slide-img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .slide-text {
    position: relative;
    color: #000;
    box-shadow: 0 2px 2px rgba(100, 100, 100, 0.5);
    padding: 0.2em;
  }

  .slide-text h3,
  .slide-text p {
    margin: 0.5em;
  }

  .container-slide-img {
    width: 100%;
    /* height: 20em; */
  }

  .controllers {
    top: 4em;
  }
}

.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
