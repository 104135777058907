.side-nav {
  height: 0;
  transition: all 300ms ease;
}

.side-nav.collapse {
  padding-top: 5em;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: #64646f33 0px 7px 29px 0px;
}

.side-nav .close-collapse-nav i {
  color: #ff0040;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5em;
  text-shadow: none;
}

.side-nav .close-collapse-nav {
  display: none;
}

.side-nav .close-collapse-nav.collapse {
  position: absolute;
  height: auto;
  margin: 1em 1em;
  background-color: transparent;
  border-width: 0;
  display: block;
  width: 45px;
  height: 45px;
  top: 0;
  right: 0;
}

.side-nav ul {
  text-align: left;
}

.navbar-brand {
  display: inline-block;
  float: none;
}

.side-nav ul li,
.side-nav .nav-brand {
  text-align: center;
  margin: 1em 1em;
  list-style: none;
}

.side-nav ul li a {
  margin: 0 auto;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  color: #1d1845;
}

.side-nav .nav-opt-icon {
  margin-right: 1em;
}
.nav-opt {
  padding: 0 1em;
}
.nav-opt > span {
  padding: 0;
}
.nav-opt > img {
  width: 40px;
  height: 40px;
}

.side-nav .nav-brand {
  text-align: center;
}

/* Navbar buttons styles */
.side-nav ul .selected {
  cursor: pointer;
}

.side-nav #services-button.selected {
  color: #1e1547;
  background-color: #d2d0da;
}

.side-nav #reports-button.selected {
  color: #ff0040;
  background-color: #ffcbda;
}

.side-nav #account-button.selected {
  color: #05008f;
  background-color: #d1cce9;
}

/* Navbar buttons texts styles */
.side-nav ul .selected i {
  text-shadow: none;
}

.side-nav ul #services-button.selected i {
  color: #1e1547;
}

.side-nav ul #reports-button.selected i {
  color: #ff0040;
}

.side-nav ul #account-button.selected i {
  color: #05008f;
}

@media screen and (min-width: 850px) {
  .side-nav,
  .side-nav.collapse {
    padding-top: 2em;
    width: 190px;
    height: 100%;
    position: fixed;
    box-shadow: #64646f33 0px 7px 29px 0px;
    border-right: 0.1em solid #ccc;
    background-color: #fff;
  }

  .side-nav .close-collapse-nav.collapse {
    display: none;
  }

  .close-collapse-nav {
    display: none;
  }

  .side-nav .nav-brand {
    display: block;
    width: 100%;
    margin: 0;
  }

  .side-nav .nav-brand .nav-logo {
    width: 90%;
  }

  .side-nav ul li a {
    width: 100%;
    justify-content: left;
    margin: 0;
  }
}

@media screen and (max-width: 844px) {
  .side-nav .nav-brand {
    width: 15%;
    margin: auto;
  }
  .side-nav .nav-brand > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 820px) {
  .side-nav .nav-brand {
    width: 70%;
    margin: auto;
  }
}
@media screen and (max-width: 390px) {
  .side-nav > .nav-brand {
    width: 350px;
    height: 380px;
    padding: 1em;
    overflow: hidden;
    margin: 0 auto;
  }
  .side-nav > .nav-brand > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
