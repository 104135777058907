.main-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3em;
  width: 100%;
  height: 100vh;
  margin: auto;
}
.sub-main-404 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-main-404 > span {
  font-size: 8em;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}
.main-404 > h3 {
  font-size: 4em;
  padding-left: 1em;
}
