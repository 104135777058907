/* .rating-stars-container {
    justify-content: center;
} */

.rating-paw {
  margin-right: 0.2em;
}

.rating-paw.empty {
  color: #9c9c9c;
}

.rating-paw.fill {
  color: #eb0045;
}