.report-card .left-column,
.preference-card .left-column {
  border-radius: 1rem 0 0 1rem;
  width: 45%;
  margin: 0;
}

.left-column img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.report-card,
.preference-card {
  border-radius: 1rem;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #bbb9c7;
  display: flex;
  margin: 1rem;
  overflow: hidden;
  height: 10rem;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
}

.right-column {
  padding: 0 1rem;
  width: 45%;
}

.right-column > h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
}

.report-card .right-column h4,
.report-card .right-column p {
  color: #1e1547;
  margin: 1em 0 0 0;
}

.right-column p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rating-stars-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5em;
}

.rating-star {
  color: #1e1547;
}

.selected-card {
  border-color: #ec1151;
}

.preference-card .right-column {
  padding: 2em 1.5em;
}

.report-card .delete-report-button {
  background-color: transparent;
  margin: 1em;
  margin-left: auto;
  height: 1.2em;
  border-width: 0;
}

.report-card .delete-report-button i {
  text-shadow: none;
  color: #bbb9c7;
  font-weight: 600;
  font-size: 1.2em;
  background-color: transparent;
  cursor: pointer;
}

.selected-service-card {
  border-color: #ec1151;
}

@media (max-width: 620px) {
  .preference-card .left-column {
    width: 80%;
  }
}

@media (max-width: 490px) {
  .preference-card {
    margin: 1rem;
    overflow: hidden;
    height: 10rem;
  }

  .preference-card .left-column {
    width: 100%;
  }

  .preference-card .right-column {
    padding: 1em;
  }
}
