.main-details-other-user {
  background-color: #fff;
  position: relative;
  max-width: 420px;
  min-width: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 700;
}

.back-button-other-user {
  position: absolute;
  top: 1em;
  left: 1em;
  width: 40px;
  height: 40px;
  border: 0.1em solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.back-button-other-user > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-info-other-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.details-imgs-other-user {
  width: 100%;
  border: 1px solid #000;
  height: 400px;
  border-radius: 0 0 50% 50%;
  margin-bottom: 4em;
  overflow: hidden;
}
.details-imgs-other-user > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pets-other-user {
  width: 100%;
  margin-top: 1em;
}

.list-report-other-user {
  margin-top: 2em;
}
.info-pets-other-user,
.info-reports-other-user {
  display: flex;
}
.info-reports-other-user {
  display: flex;
  margin-bottom: 1.5em;
}
.description-pets-other-user,
.description-reports-other-user {
  width: 100%;
  overflow: hidden;
  margin-left: 1em;
}
.img-pets {
  width: 80px;
  height: 70px;
  border-radius: 50%;
  background-color: #ccc;
  overflow: hidden;
}
.img-pets > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contain-info-pet,
.contain-info-reports {
  margin-bottom: 1em;
  overflow: hidden;
}
.contain-info-pet > p,
.contain-info-reports > p {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.slides-images-other-user,
.slider-images-pet-other-user,
.slider-instagram-pet-other-user {
  display: flex;
  width: 100%;
  gap: 0.5em;
  padding: 0 1em 1em 1em;
  overflow: auto;
}
.slider-images-pet-other-user,
.slider-instagram-pet-other-user {
  padding: 0 1em 1em 0;
  margin-bottom: 1em;
}
.slider-instagram-pet-other-user {
  padding: 0 1em 1em 0;
  margin-top: 0.3em;
}
.slides-images-other-user::-webkit-scrollbar,
.slider-images-pet-other-user::-webkit-scrollbar,
.slider-instagram-pet-other-user::-webkit-scrollbar {
  height: 0.4em;
  margin-left: 1em;
}
.slides-images-other-user::-webkit-scrollbar-thumb,
.slider-images-pet-other-user::-webkit-scrollbar-thumb,
.slider-instagram-pet-other-user::-webkit-scrollbar-thumb,
.main-details-other-user::-webkit-scrollbar-thumb {
  background-color: #28225a;
  border-radius: 3em;
}
.main-details-other-user::-webkit-scrollbar {
  width: 0.8em;
}

.container-images-other-user,
.container-images-pet-other-user,
.container-instagram-pet-other-user,
.container-instagram-pet-other-user {
  cursor: pointer;
}

.container-images-other-user > img,
.container-images-pet-other-user > img,
.container-instagram-pet-other-user > img,
.container-instagram-pet-other-user > img {
  width: 100px;
  height: 100px;
  border-radius: 1em;
}
.section-pets-other-user {
  padding: 1em;
}

.section-reports-other-user {
  padding: 1em;
}

.buttons-filters-reports {
  margin-top: 1em;
  display: flex;
  justify-content: flex-start;
  gap: 6px;
}
