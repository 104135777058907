.account-main {
  display: flex;
}
.account-view-container {
  background-color: #1e1547;
  padding-top: 5em; /* 3%;*/
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.account-view-container .corner-decoration {
  position: absolute;
  right: 0;
  top: 0;
  color: #302756;
}

.account-view-container .top-container {
  display: flex;
}

.account-view-container .top-container .profile-pict {
  background-color: #f4003d;
  color: #a80044;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  text-align: center;
  vertical-align: middle;
  border-width: 0;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('https://definicion.de/wp-content/uploads/2013/03/perro-1.jpg'); */
  background-size: cover;
  overflow: hidden;
}

.profile-pict > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #fff;
}

.account-view-container .top-container .profile-pict i {
  font-size: 2em;
  color: #f4003d;
}

.account-view-container .top-container .account-info {
  padding-left: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.3em;
}

.account-view-container .top-container h3,
.account-view-container .top-container p {
  color: white;
}

.account-view-container .top-container a {
  color: #f4003d;
}
/* 
.account-view-container .bottom-container .right-panel .petlist-container {
    display: none;
    display: flex;
} */

.account-view-container .bottom-container .account-actions {
  flex-grow: 1;
  /* margin: auto; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.account-view-container .bottom-container {
  margin-top: 1em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2em 2em 0 0;
  padding: 1.5em 0;
  gap: 1em;
}

.account-view-container .bottom-container .account-actions a.logout {
  color: #ff0043;
}

.account-view-container .bottom-container a {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #d2d0da;
  height: 3em;
  display: flex;
  align-items: center;
}

.account-view-container .bottom-container .right-panel a {
  border-width: 0;
}

footer.account-footer {
  display: flex;
  align-items: flex-end;
  gap: 1em;
  margin-top: auto;
}

footer.account-footer .logo-footer {
  height: 5em;
}

.account-view-container .bottom-container a,
footer.account-footer .logo-footer {
  padding-left: 1.5em;
}

.account-view-container .bottom-container a:first-of-type {
  border-width: 0;
  display: none;
}

footer.account-footer p {
  color: #a5a2b5;
}

.account-view-container .selected {
  font-weight: 600;
}

@media screen and (min-width: 850px) {
  .account-view-container .bottom-container {
    flex-direction: row-reverse;
    background-color: transparent;
    gap: 1em;
    padding: 0 1em;
    align-content: flex-start;
    height: 100vh;
    overflow: auto;
  }

  .account-view-container .bottom-container .right-panel,
  .account-view-container .bottom-container .account-actions {
    flex-grow: 0;
    padding: 1.5em 0;
    border-radius: 2em 2em 0 0;
    background-color: white;
    height: 100%;
  }

  /* .account-view-container .bottom-container .account-actions {
        max-width: 350px;
    } */

  .account-view-container .bottom-container .right-panel {
    max-width: calc(100vw - 588px);
    flex-grow: 1;
    padding: 1.5em 0;
    display: block;
  }

  .account-view-container .bottom-container a:first-of-type {
    display: flex;
  }
}

@media screen and (max-width: 850px) {
  .account-view-container {
    overflow: auto;
  }
}
@media screen and (max-width: 420px) {
  .account-view-container .bottom-container .account-actions {
    min-width: inherit;
  }
}
