.report-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1em;
  margin: 1em;
}

.details-container {
  background-color: white;
  height: 100%;
  left: 0;
  top: 0;
  padding-bottom: 3em;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  z-index: 7;
  position: relative;
  overflow: auto;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.details-container::-webkit-scrollbar {
  display: none;
}

.details-container .back-button {
  /* position: relative; */
  position: absolute;
  left: 2em;
  top: 3em;
  background-color: #eb0045;
  width: 3em;
  height: 3em;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: white;
  z-index: 3;
  border-style: none;
  overflow: hidden;
}

.back-button > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-container .back-button i {
  font-size: 1.5em;
  text-shadow: none;
}

.details-container .edit-button {
  /* position: relative; */
  position: absolute;
  right: 2em;
  bottom: 3em;
  background-color: #1d1845;
  width: 3em;
  height: 3em;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: white;
  z-index: 3;
  border-style: none;
}

.details-container .edit-button i {
  font-size: 1.5em;
  text-shadow: none;
}

.details-container .details-imgs {
  position: relative;
  width: 420px;
  margin: 0 auto;
}

.details-container .report-img {
  position: relative;
  height: 400px;
  width: 100%;
  border-radius: 0 0 50% 50%;
  object-fit: cover;
  z-index: 2;
}

.details-container .background-img {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.details-container h2,
.details-container .place-categories {
  color: #1f1646;
  margin: 1em auto;
  text-align: center;
}

.details-container .place-categories {
  margin-bottom: 2em;
}

.details-container .loading-message {
  margin: 1em;
}

.details-container .comments-container {
  overflow: auto;
}

.comment-container {
  display: flex;
  padding: 1em;
}

.comment-container .comment-left {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.comment-container .comment-left .user-profile-img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  object-fit: cover;
}

.comment-container .comment-right {
  padding: 0.5em;
}

.comment-container .comment-right p {
  word-break: break-word;
}

.comment-right .comment {
  margin: 0.5em;
  text-align: left;
}

hr {
  height: 2px;
  background-color: #dfdfe6;
  border: none;
}

@media (min-width: 600px) {
  .details-container {
    min-width: 420px;
    max-width: 420px;
  }
}
