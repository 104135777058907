.home-view-left {
  width: 100%;
  height: 70vh;
  text-align: center;
}

.home-view-left img {
  height: 100%;
  width: 100%;
}

.home-view-right {
  width: 80%;
  padding-top: 2%;
  margin: 1em auto;
  text-align: center;
}

.home-view-right p {
  margin: 1em 0;
}

.home-view-right .auth-buttons-container {
  margin: 0.5em auto;
  width: 80%;
}

.home-view-right .corner-decoration {
  display: none;
}

@media (min-width: 440px) {
  .home-view-left img {
    width: auto;
  }
}

@media (min-width: 870px) {
  .home-view {
    height: 100vh;
    display: flex;
    align-content: space-around;
  }

  .home-view-left {
    width: 40%;
    margin-left: 5%;
  }

  .home-view-left img {
    width: 100%;
    height: auto;
  }

  .home-view-right {
    width: auto;
    padding-top: 9em;
    padding-left: 3em;
    margin: 0;
    text-align: left;
  }

  .home-view-right p {
    margin: 1em 0;
  }

  .home-view-right .auth-buttons-container {
    width: 80%;
    margin: 0 auto;
  }

  .home-view-right .corner-decoration {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 414px) {
  .home-view-left {
    height: 100%;
  }
}
