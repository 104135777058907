.services-view {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: white;
}

.title_services {
  text-align: center;
  font-size: 1.5em;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-d,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  padding: 0;

  overflow: hidden !important;
}

.img-marker {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.img-marker > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-marker > h3 {
  text-align: center;
}
.img-marker > p {
  text-align: center;
  padding: 0.3em;
  font-weight: 600;
}

/* estilos filtros del mapa. */
.main-map {
  position: relative;
  display: flex;
  height: 100vh;
}

.main-map .showing-details {
  display: none;
}

.main-filter {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 2em;
  left: 3em;
  width: calc(100% - 130px);
}
.main-filter > .group-input {
  position: relative;
  width: 100%;
}
.main-filter > .group-input-filter {
  position: relative;
  display: flex;
  margin-left: 3em;
  z-index: 200;
}
#address-map {
  color: #1e1547;
  font-size: 1.2em;
  outline: none;
  padding: 0.5em 0.5em 0.5em 3em;
  border: none;
  width: 100%;
  border-radius: 5em;
  box-shadow: 0 0 6px rgb(0 0 0 / 50%);
  z-index: 200;
  position: relative;
}
.main-filter > .group-input > .fa-magnifying-glass {
  position: absolute;
  margin-left: 1em;
  top: 0.56em;
  font-size: 1.3em;
  font-weight: bold;
  z-index: 300;
}
.main-filter > .group-input > input::placeholder {
  font-size: 1.1em;
  color: #1e1547;
}
.fa-filter {
  color: #0d68c9;
  background-color: #fff;
  padding: 0.5em;
  border-radius: 50%;
  font-size: 1.3em;
}
.group-filter {
  position: relative;
  width: 15em;
  margin-left: 2em;
  background: #fff;
  border-radius: 5em;
  padding: 0.4em 0.4em 0.4em 1em;
}
.group-filter > span {
  font-size: 0.75em;
  color: #db0707;
}
.img-filter {
  position: absolute;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  overflow: hidden;
  top: -9px;
  right: -11px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}
.img-filter > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.4em;
}
.fa-filter,
.group-filter,
.img-filter {
  box-shadow: 0 0 6px rgb(0 0 0 / 50%);
}
.fa-xmark {
  color: red;
  background-color: black;
  padding: 0.5em 0.65em;
  border-radius: 50%;
  font-size: 1.3em;
}

/* estilos menu ventana de filtros */
.filter-map {
  position: relative;
}
.main-dropdown {
  position: absolute;
  top: 4em;
  left: -170px;
  width: 400px;
  background-color: #fff;
  border-radius: 1.5em;
  outline: 0.1em solid rgba(0, 0, 0, 0.4);
}
.main-dropdown > section {
  padding: 1em;
}
.title-dropdown {
  display: flex;
  align-items: center;
}
.title-dropdown > h2 {
  margin-left: 0.5em;
}

.type-view-dropdown,
.hours-open {
  display: flex;
  flex-direction: column;
}
.group-type {
  display: flex;
}

.group-type > label::before,
.hours-open > label::before {
  content: '';
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  border: 0.1em solid black;
  margin-right: 0.5em;
  box-shadow: inset 0 0 0 0.1em #fff;
}
.group-type > input:checked + label::before,
.hours-open > input:checked + label::before {
  background-color: red;
}
.search-date {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search-date > p {
  margin-bottom: 0;
}
.days-week {
  margin-top: 0.6em;
  border: 0.2em solid #dfdfe6;
  border-radius: 0.4em;
  padding: 0.4em;
}
.days-week > label {
  line-height: 1;
  padding: 0.3em;
  margin: 0.23em;
  border-radius: 0.5em;
}
.days-week > input:checked + label {
  background-color: red;
}

/* estilos menu de categorias */
.main-categories {
  width: 650px;
  background-color: #fff;
  position: absolute;
  top: 4em;
  right: -1em;
  border-radius: 1.5em;
  padding-top: 1em;
  outline: 0.1em solid rgba(0, 0, 0, 0.4);
}
.main-categories > section {
  padding: 1em;
  color: #1e1547;
}
.title-category {
  display: flex;
  align-items: center;
}
.title-category > img {
  width: 3em;
  height: 3em;
  opacity: 0.4;
  margin-right: 0.5em;
}
section.group-help-animal > p,
.group-places-category > p,
.group-shop > p {
  margin-bottom: 0.7em;
}

.container-places {
  display: flex;
}

.places-iten {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1em;
  cursor: pointer;
}
.places-iten > img {
  width: 3em;
  height: 3em;
  margin-bottom: 0.5em;
}

.places-iten > label {
  cursor: pointer;
}

.group-button {
  display: flex;
  justify-content: flex-end;
}

.btn-select {
  background-color: #1e1547;
}

.close-categorie {
  display: block;
  padding-top: 25%;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: red;
  font-weight: bold;
  font-size: 1.8em;
}

/* estilos para la busqueda de direcciones */
.group-input {
  position: relative;
}

.container-address {
  position: absolute;
  top: 4em;
  width: 500px;
  height: 600px;
  overflow: auto;
  background-color: #fff;
  border-radius: 1em;
  z-index: 200;
}
.container-address::-webkit-scrollbar {
  display: none;
}
.iten-address {
  padding: 0.5em 1em;
}
.iten-address:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.iten-address > p {
  margin-bottom: 0;
}

/* estilos vista de mapa por tarjetas */
.main-card-views {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 1em;
  padding: 9em 1em 1em 1em;
  z-index: 60;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: auto;
}
.card-place {
  border: 0.2em solid #e9e9e9;
  padding: 0.3em;
  border-radius: 0.6em;
}

.container-btn-search {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 6.5em;
  z-index: 500;
}
.btn-search {
  background-color: #1e1547;
  height: 3em;
}

/* media screen. */
@media screen and (min-width: 750px) {
  .main-map .showing-details {
    display: flex;
  }
  .main-map .main-card-views {
    display: grid;
  }
}

@media screen and (max-height: 450px) {
  .main-categories {
    overflow: auto;
    width: 580px;
    height: 283px;
  }
  .container-places {
    flex-wrap: wrap;
  }

  .group-input-filter {
    bottom: 1.5em;
  }
}

@media screen and (max-width: 860px) {
  .main-categories {
    overflow: auto;
    width: 580px;
    height: 283px;
  }
  .container-places {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .main-filter {
    flex-direction: column;
    justify-content: space-between;
    height: 94vh;
  }

  .group-input-filter {
    justify-content: space-between;
  }

  .main-categories {
    bottom: 0;
    right: 0;
    padding-top: 0;
    border-radius: 1.5em 1.5em 0 0;
    width: 100%;
    position: fixed;
    top: inherit;
    overflow: auto;
    height: 100vh;
  }
  .container-places {
    flex-wrap: wrap;
  }
  .main-dropdown {
    bottom: 0;
    left: 0;
    padding-top: 0;
    border-radius: 1.5em 1.5em 0 0;
    width: 100%;
    position: fixed;
    top: inherit;
    z-index: 150;
  }
  .btn-search {
    background-color: #1e1547;
    margin: 7em 0 0 7%;
  }
}
@media screen and (max-width: 420px) {
  .img-filter {
    top: -8px;
    right: -40px;
  }
  .group-filter {
    font-size: 0.85em;
  }
}
