/* Notifications styles */

/* .account-notifications-container {
    padding: 0 1.5em;
} */

.account-notifications-container,
.account-about-container,
.account-policy-container,
.account-editprofail-container,
.details-container-docs,
.container-edit-pet {
  position: absolute;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: white;
  top: 0;
  left: 0;
  padding: 5em 3em;
  overflow: auto;
}

.account-notifications-container .back-button,
.account-about-container .back-button,
.account-policy-container .back-button,
.account-editprofail-container .back-button {
  /* position: relative; */
  position: absolute;
  left: 2em;
  top: 2em;
  background-color: #eb0045;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  cursor: pointer;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: white;
  z-index: 5;
  text-shadow: none;
}

/* estilos ventana de editar */
.form-group > input,
select {
  font-size: small;
  border: 0.2em solid rgba(196, 190, 190, 0.6) !important;
}
.form-data-edit {
  flex-direction: column;
  gap: 2em;
}

.image-edit {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  border: 0.2em solid rgba(196, 190, 190, 0.8);
  margin: 2em 0 1em 0;
  position: relative;
}

.image-edit > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.delete-photo-profile,
.add-photo-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  cursor: pointer;
  border: none;
}
.add-photo-profile {
  outline: solid 0.1em #bbb9c7;
}
.delete-photo-profile > img,
.add-photo-profile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.group-instagran {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  padding: 1em;
}
.info-instagran {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}
.delete-instagran {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.delete-instagran > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.form > hr {
  opacity: 1 !important;
}

.btn-save-edit {
  margin-top: 1em;
}

.group-buttons.edit-buttons {
  justify-content: center;
}
.account-notifications-container .notification-card {
  border-radius: 0.5em;
  border: 1px solid;
  border-color: #bbb9c7;
  display: flex;
  margin-top: 1em;
}

.account-notifications-container .right-arrow {
  margin-left: auto;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  color: #ff0043;
}

.notification-card-left {
  background-color: #e8e7ec;
  width: 3em;
  border-radius: 0.5em 0 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-card-left i {
  text-shadow: none;
  color: #bbb9c7;
}

.notification-card-right {
  padding: 1em;
}

/* Pet list styles */

.petlist-container {
  height: 100%;
  max-width: 95vw;
  display: flex;
  overflow: auto;
  margin: 0 auto;
  /* margin-bottom: 1.5em; */
}
.petlist-container::-webkit-scrollbar {
  width: 0.3em;
}
.petlist-container::-webkit-scrollbar-thumb {
  border-radius: 5em;
  background-color: rgba(0, 0, 0, 0.3);
}

.petlist-container .no-pets {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.petlist-container > .pet-info-reports {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  min-width: 250px;
  cursor: pointer;
}
.petlist-container > .pet-info-reports:hover {
  background-color: #bbb9c7;
}
.info-pet-reports {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3em;
  margin-left: 1em;
  overflow: hidden;
}
.info-pet-reports > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.petlist-container > .pet-info-reports > img {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  min-width: 4em;
  min-height: 4em;
}

.petlist-container .petlist-footer {
  min-width: 200px;
}

.petlist-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.petlist-footer .add-button {
  font-size: 20px;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  background-color: transparent;
  border-color: #d2d0da;
  cursor: pointer;
  border-style: solid;
  overflow: hidden;
}

.add-button > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.petlist-footer .add-button i {
  color: #ff0043;
  text-shadow: none;
}

.buttons-pets {
  display: flex;
  justify-content: flex-end;
  gap: 0.4em;
  margin-left: 1em;
}

.button.btn-edit,
.button.btn-del {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  font-size: 10px;
}

.button.btn-edit > img,
.button.btn-del > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
/* estilos detalle documentacion de mascota */
.main-container-images {
  position: relative;
}
.details-container-docs {
  position: relative;
  padding: 2em;
  width: 100%;
  height: 100%;
}
.details-container-docs::-webkit-scrollbar {
  width: 0.6em;
}
.details-container-docs::-webkit-scrollbar-thumb {
  border-radius: 5em;
  background-color: #11026b;
}

.back-button-docs {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 2em;
  border: none;
  cursor: pointer;
}
.button-edit-doc {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 1em;
  right: 1em;
  border: 0.1em solid #ccc;
  cursor: pointer;
  z-index: 200;
}
.back-button-docs > img,
.button-edit-doc > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.details-imgs-doc {
  width: 250px;
  height: 250px;
  border-radius: 2em;
}
.details-imgs-doc > img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: solid 0.2em #d2d0da;
  border-radius: 3em;
}
.info-pet-doc-container {
  display: flex;
  justify-content: flex-start;
  gap: 1.2em;
  align-items: center;
  padding: 1em;
  margin: 2em;
}

.info-pet-doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  overflow: hidden;
}
.info-pet-doc > h2 {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2.3em;
}
.info-pet-doc > p {
  font-size: 1.5em;
}
.btn.btn-red {
  background-color: red;
  width: 200px;
  margin-left: 0;
}

.button-doc {
  padding: 1em 0;
}

.refresh {
  margin-left: 0.5em;
  font-size: 1.7em;
  color: rgba(5, 110, 5, 0.4);
  cursor: pointer;
}
.btn-group-doc {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.group-buttons-doc {
  margin: 0.5em 0 1em 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn.btn-add-doc {
  color: red;
  width: 200px;
  text-align: center;
  outline: 0.2em solid #bbb9c7;
}
.slide-main-container {
  width: 250px;
  height: 250px;
}
.list-container-img-slide {
  position: relative;
  height: 100%;
}
.container-images {
  width: 100%;
  height: 100%;
  border-radius: 2em;
  border: 0.2em solid #d2d0da;
  overflow: hidden;
}
.list-images-details {
  position: relative;
  width: 100%;
  height: 100%;
  left: 6em;
  display: flex;
}
.list-images-details > li {
  list-style: none;
  width: 100%;
  height: 100%;
}

.list-images-details > li > img {
  width: 250px;
  height: 250px;
  border: solid 0.2em #d2d0da;
  object-fit: cover;
}

.dot-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.3em;
  margin-top: 0.4em;
}
.dot-item {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: black;
}

/* estilos documentos vista previa. */
.container-images-docs {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 0.6em;
  outline: 0.1em solid #bbb9c7;
  padding: 0.5em;
  border-radius: 3px;
  overflow: auto;
  margin-bottom: 0.5em;
}
.container-images-docs::-webkit-scrollbar {
  height: 0.5em;
}
.container-images-docs::-webkit-scrollbar-thumb {
  border-radius: 5em;
  background-color: #11026b;
}

.item-images {
  cursor: pointer;
}
.item-images > img {
  width: 60px;
  height: 60px;
}

/* fin estilos detalles */

/* estilos edicion de mascotas desde la cuenta */
.container-edit-pet {
  position: relative;
  padding: 1em;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.container-edit-pet::-webkit-scrollbar {
  width: 0.5em;
}
.container-edit-pet::-webkit-scrollbar-thumb {
  border-radius: 5em;
  background-color: #11026b;
}
.container-edit-pet > h1 {
  padding: 1em 0;
}
.container-pet-images {
  padding: 0.5em;
  display: flex;
  gap: 10px;
  overflow: auto;
}
.container-pet-images::-webkit-scrollbar {
  height: 0.5em;
}
.container-pet-images::-webkit-scrollbar-thumb {
  border-radius: 5em;
  background-color: #11026b;
}
.images-item {
  position: relative;
}
.images-item > .delete-images {
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  border: none;
}
.images-item > img {
  border: 0.1em solid #ccc;
  border-radius: 25px;
  padding: em;
  width: 100px;
  height: 100px;
}

section.container-form-edit > .form > .form-group > .arrow-down {
  top: 0.5em;
  transition: all 200ms ease;
}
select:active + .arrow-down {
  transform: rotate(180deg);
}

.container-instagram {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}
.info-instagran-edit-pet {
  display: flex;
  flex-direction: column;
}

.delete-instagran-edit-pet,
.btn-back {
  border: none;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.delete-instagran-edit-pet > img,
.btn-back > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-button-edit {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.2em;
  gap: 2em;
}

@media screen and (max-width: 850px) {
  .details-container-docs,
  .container-edit-pet {
    position: absolute;
    min-width: 400px;
  }
  .info-pet-doc-container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .info-pet-doc-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
  }

  .details-imgs-doc {
    width: 250px;
    height: 250px;
  }
  .info-pet-doc {
    align-items: center;
  }
}
@media screen and (min-width: 850px) {
  .account-notifications-container,
  .account-policy-container,
  .account-about-container,
  .account-editprofail-container {
    position: static;
    z-index: 0;
    width: auto;
    height: auto;
    background-color: white;
    padding: 0 1.5em;
    height: 100%;
  }

  .account-notifications-container .back-button,
  .account-policy-container .back-button,
  .account-about-container .back-button,
  .account-editprofail-container .back-button {
    display: none;
  }

  .petlist-container {
    flex-direction: column;
  }

  .petlist-container .no-pets {
    display: flex;
  }

  .petlist-container > .pet-info-reports {
    padding: 0.3em 1.5em;
    margin-bottom: 0.5em;
    margin-right: 0;
  }

  .petlist-footer {
    border-top: 1px solid #d2d0da;
  }

  .petlist-footer {
    padding-top: 1em;
    margin-top: auto;
  }
  .container-images-docs {
    max-width: calc(100vw - 350px);
  }
}

@media screen and (max-width: 740px) {
  .group-buttons {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 420px) {
  .details-container-docs,
  .container-edit-pet {
    min-width: inherit;
  }
  .info-pet-doc {
    overflow: visible;
  }
  .delete-instagran {
    height: 35px;
  }
}
