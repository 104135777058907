.configure-search-view {
    padding: 10rem 5%;
}

.configure-search-view .corner-decoration {
    position: absolute;
    top: 0;
    right: 0;
}

.configure-search-view>h3,
.configure-search-view>p {
    margin-top: 2em;
}

.configure-search-view .finish-button {
    background-color: #1e1547;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.finish-button-container {
    margin-top: 1em;
    width: 100%;
    text-align: center;
}

.configure-search-view .visualization-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.configure-search-view .buttons-container span {
    max-width: 500px;
}

@media (min-width: 450px) {
    .configure-search-view {
        padding: 10em 5%;
    }
}