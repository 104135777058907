.container-cel {
  height: 100vh;
  overflow: hidden;
}
.footer {
  height: 80%;
  width: 100%;
  overflow: hidden;
}
.footer > img {
  width: 100%;
  height: 100%;
  background-size: cover;
}
