.errors {
  font-size: 0.8em;
  font-weight: 600;
  color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}
.container-form {
  --border-form: 0.2em solid rgba(196, 190, 190, 0.6);
  --border-img: 1px solid rgba(196, 190, 190, 1);
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
  position: relative;
  overflow: hidden;
}
.form {
  margin-left: -1em;
}
.form-data {
  display: flex;
  justify-content: space-between;
}
.img-logo {
  width: 15em;
  margin-bottom: 2em;
}
section {
  width: 100%;
  margin-right: 1em;
}
section > h1 {
  line-height: 2;
  font-weight: 600;
  color: #1e1547;
}

section > p {
  margin-bottom: 1em;
}
.form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1em;
  position: relative;
}
.img_container {
  display: flex;
  align-items: center;
  margin-bottom: 1.2em;
  margin-left: 0.7em;
}
.row.row-6 {
  width: 50%;
  display: none;
}

.profile_img {
  width: 4.4em;
  height: 4.4em;
  border-radius: 50%;
  border: var(--border-img);
  overflow: hidden;
}
.profile_img > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.see-images {
  color: rgb(59, 140, 247);
  margin-left: 0.3em;
}

.see-images:hover {
  color: rgb(7, 68, 148);
  text-decoration: underline;
  cursor: pointer;
}

.form-group > label {
  position: absolute;
  top: -0.5em;
  left: 0.7em;
  background-color: white;
}
.form-group > label[for='politica'] + p {
  margin-top: 3em;
  margin-left: 1em;
}
.form-group > label[for='politica'] {
  position: relative;
  left: 1.5em;
}
.form-group > label[for='politica']::before {
  content: '';
  position: absolute;
  width: 1.1em;
  height: 1.1em;
  border-radius: 0.4em;
  border: 0.18em solid rgba(196 190 190 / 0.8);
  margin-left: -2em;
  margin-top: -0.2em;
  box-shadow: inset 0 0 0 0.17em #fff;
}
#politica:checked + label[for='politica']::before {
  background-color: #1e1547;
}
#politica {
  display: none;
}

.form-group > input,
select {
  background-color: transparent;
  border: var(--border-form);
  border-radius: 1.2em;
  margin-left: -0.5em;
  outline: none;
  padding: 1em;
  box-shadow: 0 0.14em rgba(0 0 0 /0.3);
  appearance: none;
  width: 100%;
}
.arrow-down-pets {
  position: absolute;
  color: red !important;
  right: 0.7em;
  font-size: 1.6em;
  top: 0.5em;
  transition: all 200ms ease;
}
.form-group > input:focus,
select:focus {
  border: 0.2em solid rgba(255, 0, 0, 0.6);
}
select:active + .arrow-down-pets {
  transform: rotate(180deg);
}
.form-group > label > input {
  margin-left: 0.5em;
}
.form-group > label > a {
  text-decoration: none;
  font-weight: 500;
}
.btn {
  padding: 1em 2em;
  border-radius: 1.5em;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 0.8em;
  cursor: pointer;
  margin-left: 0.7em;
  box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.3);
}
.btn-submit,
.btn-user-submit,
.btn-camera {
  background-color: #1e1547;
  font-weight: bold;
}
.btn-cancel,
.btn-omitir {
  background-color: #fff;
  color: red;
}
.hidden {
  display: none;
}

.btn-camera {
  padding: 0.8em 1.3em;
  margin-left: 2em;
}
.button-group-form {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2em;
}

.group-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.form > hr {
  opacity: 0.4;
}

.container-form-pets {
  display: flex;
  justify-content: space-around;
}
/* Styles Acordeon type pets*/

.select.pet {
  overflow: hidden;
}
.input-pet {
  display: flex;
  align-items: center;
  padding: 0.9em 0.85em;
  border: 0.16em solid rgba(196, 190, 190, 0.6);
  outline: none;
  border-radius: 1.2em;
  margin-left: -0.5em;
  cursor: pointer;
  box-shadow: 0 0.14em rgba(0 0 0 /0.3);

  transition: all 150ms ease;
}
.container-types {
  border-left: 0.14em solid rgba(196, 190, 190, 0.6);
  border-right: 0.14em solid rgba(196, 190, 190, 0.6);
  border-radius: 0 0 1.2em 1.2em;
  display: flex;
  padding: 0;
  max-height: 0;
  margin-top: 0;
  margin-left: -0.5em;
  transition: all 150ms ease;
}
.label-pet {
  position: absolute;
  top: -0.5em;
  left: 0.7em;
  background-color: white;
}

.border-type-pet {
  border-radius: 1.2em 1.2em 0 0;
}

.show {
  max-height: none;
  padding: 0.5em;
  border-bottom: 0.14em solid rgba(196, 190, 190, 0.6);
}
.show > .item-type {
  display: flex;
}
.button-open-terms {
  color: red;
  border: none;
  background-color: transparent;
  font-size: 1em;
  cursor: pointer;
}
.container_modal_condition {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.input-pet > p {
  margin-left: 0.5em;
}

.hide {
  display: none;
}
@media screen and (max-width: 1000px) {
  .container-form-pets {
    display: block;
  }
}
@media screen and (max-width: 860px) {
  .container-form {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .row-6 {
    width: 100%;
  }
}

@media screen and (max-width: 740px) {
  .form-data {
    flex-direction: column;
  }
  .group-buttons {
    margin-top: 2em;
  }
}

@media screen and (max-width: 570px) {
  .group-buttons {
    margin-top: 3em;
  }
  .container-form {
    width: 550px;
  }
}
@media screen and (max-width: 414px) {
  .container-form {
    width: 100%;
    padding: 1em;
  }
}
