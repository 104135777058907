.reports-view {
  width: 100%;
  margin: 0;
}

.details-view {
  height: 100%;
}
.form-pet-lost {
  height: 100vh;
}

/* estilos del container de reportes */

.report-container {
  overflow: hidden;
  position: relative;
  background-color: #1e1547;
  padding: 5em 1em 0 1em;
  width: 100%;
  height: 100vh;
  display: flex;
}

.report-container.showing-details {
  padding: 0;
  display: block;
}

.report-container .report-container-right {
  flex-grow: 1;
  width: 100%;
}

.imgCorner {
  position: absolute;
  top: 0;
  right: 0;
}

.imgCorner {
  position: absolute;
  top: 0;
  right: 0;
}

.navbar-report {
  padding: 0 3em;
  height: 3em;
  margin: 0;
}

.navbar-report > a {
  display: inline-block;
  margin-right: 1em;
  font-size: 1.3em;
  color: rgba(255 255 255/0.5);
  position: relative;
  height: 100%;
}

.active-page {
  color: #fff !important;
  font-weight: bold;
}
.active-page::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.3em;
  background-color: red;
  bottom: 0;
  border-radius: 2em;
}

.form-report-container {
  width: 100%;
  border-radius: 3em 3em 0 0;
  padding: 3em 2.5em 0 2.5em;
  margin: auto;
  background-color: #fff;
  overflow: auto;
  height: 93%;
}

.form-report-container::-webkit-scrollbar {
  display: none;
}
.input-radio {
  display: inline-block;
  margin-left: 0.4em;
}
.input-radio > input[type='radio'] {
  display: none;
}
label[for='friendly'],
label[for='petLost'] {
  display: inline-block;
}

label[for='friendly']::before,
label[for='petLost']::before {
  border-radius: 50%;
}

.input-radio > input[type='radio']:checked + label[for='friendly']::before,
.input-radio > input[type='radio']:checked + label[for='petLost']::before {
  background-color: red;
}

.select-filter {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  padding-left: 1em;
}

/* estilos formulario firendly */
.form-report {
  position: relative;
  margin: 1em 0;
  width: 100%;
}
.form-report > input[type='text'],
.form-report > select,
.form-report > textarea {
  width: 100%;
  border: 0.2em solid rgba(196, 190, 190, 0.6);
  outline: none;
  padding: 1em;
  border-radius: 3em;
  box-shadow: 0 0.14em rgb(0 0 0 / 30%);
  font-size: 1em;
}
#address {
  font-size: 1em;
  padding: 1em;
}

.form-report > textarea[name='description'] {
  height: 11em;
  font-size: 1.3em;
  resize: none;
}
#pet_name {
  margin-left: -5px;
  font-size: 1em;
  padding: 1em;
}
.checkbox {
  display: inline-block;
  padding: 0.5em 1.6em;
  position: relative;
}

.checkbox::before {
  content: '';
  position: absolute;
  width: 1.1em;
  height: 1.1em;
  border-radius: 0.4em;
  border: 0.18em solid rgba(196 190 190 / 0.8);
  margin-left: -2em;
  margin-top: -0.2em;
  box-shadow: inset 0 0 0 0.17em #fff;
}
.form-report > input[type='checkbox']:checked + .checkbox::before {
  background-color: #1e1547;
}
.form-report > input[type='checkbox'] {
  display: none;
}

.form-report > label[for='address'],
.form-report > label[for='opinion'] {
  position: absolute;
  margin-left: 1.9em;
  margin-top: -0.58em;
  background-color: #fff;
  padding: 0;
}
.opinion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.rating {
  width: 25%;
  margin-right: 2em;
  text-align: center;
}
.start-container {
  display: flex;
  justify-content: center;
  font-size: 1.8em;
  color: #9c9c9c;
}

input[id^='star'] {
  display: none;
}
.paws {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.paws > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-buttom-friendly {
  display: flex;
  justify-content: space-between;
}

.btn-report {
  background-color: red;
  font-weight: bold;
  margin: 1em 0 1em 1em;
  font-size: 1.2em;
}

/* .fa-solid {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
} */

/* estilos formulario mascotas perdidas */

.btn-photo {
  background-color: #1e1547;
  font-weight: bold;
  margin: 1em 0 1em 0;
  font-size: 1.2em;
}
.row {
  display: flex;
  justify-content: space-between;
}
.row > .form-report {
  margin-left: 1em;
}

.form-report > select {
  font-size: 1em;
  padding: 1em;
  appearance: none;
  cursor: pointer;
}

.form-report > label[for^='report_type'],
.form-report > label[for='breed'],
.form-report > label[for^='pet_type'],
.form-report > label[for^='pet_name'],
.form-report > label[for^='description'] {
  position: absolute;
  margin-left: 1.9em;
  margin-top: -0.4em;
  background-color: #fff;
  padding: 0;
}

.arrow-down {
  position: absolute;
  color: red;
  right: 1em;
  font-size: 1.6em;
  top: 0.7em;
  transition: all 200ms ease;
}

.form-buttom-pet-lost {
  display: flex;
  justify-content: space-between;
}

.row > .form-report > select:active + .arrow-down {
  transform: rotate(180deg);
}

.report-container-right.showing-details {
  display: none;
}

.report-container-right.showing-details.showing-delete-report-modal {
  display: block;
}

.details-container.showing-delete-report-modal {
  display: none;
}
@media screen and (min-width: 1200px) {
  .opinion {
    flex-direction: row;
    align-items: center;
  }
  .start-container {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 800px) {
  .form-report-container {
    height: calc(100vh - 8em);
  }

  .navbar-report {
    margin-top: 5em;
  }

  .report-container {
    gap: 1em;
    padding: 0 1em;
  }

  .report-container.showing-details {
    padding: 0 1em;
    display: flex;
  }
  /* responsive formulario de lugares petfriendly */

  .details-container.showing-delete-report-modal {
    display: none;
  }
}

@media screen and (min-width: 1060px) {
  .report-container-right.showing-details {
    display: block;
  }
}

@media screen and (max-width: 414px) {
  .reports-view {
    height: 100vh;
  }
  .report-container {
    height: 100%;
  }
  .form-buttom-pet-lost,
  .form-buttom-friendly {
    flex-direction: column;
    align-items: center;
  }
  .btn-report {
    margin: 0;
  }
  .form-report-container {
    height: 112vh;
  }
}
